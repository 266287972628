.sendbird-channel-list__header {
  height: auto !important;
}

.sendbird-channel-list {
  height: 80vh !important;
  width: 100% !important;
  padding: 1rem;
}

.sendbird-channel-list-reset {
  display: block !important;
  height: auto !important;
  width: auto !important;
  padding: 0px !important;
}

.sendbird-channel-list__body > div:empty {
  display: none !important;
}

.sendbird-channel-list__body::-webkit-scrollbar {
  width: 4px;
}

.sendbird-channel-list__body::-webkit-scrollbar-thumb {
  background-color: #e2e4e8;
  border-radius: 8px;
  max-height: 64px;
}

.sendbird-conversation {
  width: auto;
  border: 0px !important;
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
  background-color: #f5f6f9 !important;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sendbird-conversation img.chat-illus {
  width: 500px;
}

.sendbird-conversation__footer {
  margin-top: auto;
  padding: 0px !important;
  background-color: #ffffff !important;
}

.badge-status {
  position: absolute;
  width: 10px;
  height: 10px;
  box-shadow: 0px 0px 0px 2px #ffffff;
  border-radius: 100%;
  bottom: 0;
  right: 0;
}

.sendbird-conversation__messages-padding {
  padding-left: 16px !important;
  padding-right: 16px !important;
  overflow-y: auto !important;
}

.sendbird-conversation__messages-padding::-webkit-scrollbar {
  width: 4px;
}

.sendbird-conversation__messages-padding::-webkit-scrollbar-thumb {
  background-color: #e2e4e8;
  border-radius: 8px;
  max-height: 64px;
}

.additional-service-wrapper > div > label {
  color: #344054;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px !important;
  background: none;
}

.additional-service-wrapper label > svg {
  position: static !important;
  margin: 0 !important;
}

.additional-service-wrapper > div > .menu-content {
  padding: 0 !important;
  background: none;
}

.dropdown-wrapper > div > div > div {
  margin: 0 !important;
  background: none !important;
  box-shadow: none !important;
}

.button-negotiation {
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  font-family: var(--font-openSans);
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  border-width: 1px;
}

@media screen and (max-width: 1024px) {
  .sendbird-conversation {
    min-width: 425px;
  }

  .sendbird-conversation img.chat-illus {
    width: 350px;
  }
  .sendbird-conversation__footer,
  .sendbird-conversation__messages,
  .sendbird-conversation__messages-list {
    max-width: 425px;
  }
}

.text-balance {
  text-wrap: balance;
}
